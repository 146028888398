import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ArticleContainer from '../containers/Article'
import Layout from '../components/Layout'
import Redirect from '@components/Redirect'

const Article = ({ data, pageContext, location }) => {
  let { locale, id, slug, pathname } = pageContext

  const article = data?.article?.nodes?.[0]
  const seoObj = data?.article?.nodes?.[0]?.seo
  // remove dupes from response
  const relatedArticles = data?.relatedArticles?.nodes
  const relatedArticlesFiltered = [
    ...new Set(relatedArticles?.map(r => r.id)),
  ].map(id => relatedArticles.find(r => r.id === id))

  const defaultShareImage =
    '//images.ctfassets.net/00d5npkzl3wz/3KLK5fiRh6tPbDAf78EX1g/cf6d5f1bc97e7cbedc8dd62f5cb1d412/spacecamp_thumbnail.jpg'

  const seo = {
    id,
    locale,
    metaTitle: seoObj?.title,
    metaDescription: seoObj?.metaDescription,
    slug,
    articleSEO: true,
    pageSEO: false,
    ogImage: {
      fixed: {
        width: seoObj?.image?.sourceLandscape?.fixed?.width || 1200,
        height: seoObj?.image?.sourceLandscape?.fixed?.height || 627,
        src: seoObj?.image?.sourceLandscape?.fixed?.src || defaultShareImage,
      },
    },
  }

  return (
    <Layout {...pageContext} seo={seo}>
      <ArticleContainer
        article={article}
        relatedArticles={relatedArticlesFiltered}
        locale={locale}
        pathname={pathname}
        location={location}
      />
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.exact({
    id: PropTypes.string,
    title: PropTypes.string,
    locale: PropTypes.string,
    pathname: PropTypes.string,
    relatedArticles: PropTypes.array,
  }).isRequired,
}

export default Article

export const query = graphql`
  query Article($id: String!, $locale: String!, $relatedArticles: [String!]) {
    article: allContentfulArticle(
      filter: { contentful_id: { eq: $id }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...articleFields
        seo {
          ...seoFragment
        }
      }
    }
    relatedArticles: allContentfulArticle(
      filter: {
        contentful_id: { in: $relatedArticles }
        node_locale: { eq: $locale }
      }
      sort: {
        fields: [publicationDate, author___id, createdAt, title]
        order: [DESC, ASC]
      }
      limit: 8
    ) {
      nodes {
        ...relatedArticleFields
      }
    }
  }
`
